import React, { Fragment, useContext, useRef, useState } from "react";
import { graphql, Link, navigate } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Section,
  SectionTitle,
  Container,
  MarkdownContent,
  BreadCrumb,
} from "../components/Section";
import {
  Sidebar,
  SidebarDefault,
  SidebarDefaultBody,
  SidebarPrimary,
  SidebarPrimaryHeader,
  SidebarPrimaryTitle,
  SidebarPrimaryBody,
} from "../components/Sidebar";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckShapeIcon,
  FillArrowLeft,
  FillArrowRight,
  PhoneIcon,
} from "../components/Icons";
import ProductForm from "../components/ProductForm";
import WhiteOutlineButton from "../components/Button/WhiteOutlineButton";
import {
  ProductCard,
  ProductFigure,
  ProductSku,
  ProductPrice,
  HelpText,
  ProductToolbar,
  ProductItemTool,
  RtoInfo,
  RtoItemInfo,
} from "../components/ProductStyle";
import ColorPalettes from "../components/ColorPalettes";
import ProductCards from "../components/ProductCard";
import { Context } from "../components/context";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BreakpointUp from "../components/Media/BreakpointUp";

const ProductTitle = styled.h1`
  color: #fff;
  margin-bottom: 0;
`;

const SectionProduct = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  @media (min-width: 1200px) {
    margin: 0 -15px;
  }
`;

const SectionLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    padding: 0 15px;
  }
  @media (min-width: 1600px) {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .product-figure {
    margin-bottom: 10px;
    @media (min-width: 1200px) {
      margin-bottom: 0;
    }
  }
`;

const SectionRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    padding: 0 15px;
  }
  @media (min-width: 1600px) {
    flex: 0 0 45%;
    max-width: 45%;
  }
`;

const PriceBox = styled.div`
  margin-bottom: 15px;
  border-radius: 20px;
  overflow: hidden;
  background: linear-gradient(to bottom, #2d2c30 0%, #161420 100%);
  color: #fff;
  padding:0;
  @media (min-width: 576px) {
    padding: 20px 15px;
  }
  @media (min-width: 1600px) {
    padding: 30px 20px;
  }
`;

const PriceBoxHeader = styled.div`
  text-align: center;
  padding:15px;
  @media (min-width: 576px) {
    padding: 0;
  }
  .product-sku {
    color: #a8a6ab;
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  .product-price {
    span {
      color: #fff;
    }
    strong {
      font-weight: 900;
    }
  }
  .product-helptext {
    color: #a8a6ab;
  }
  .product-helptext{
    margin-bottom:0;
    @media (min-width: 576px) {
      margin-bottom:15px;
    }
  }
`;

const PriceInnerBox = styled.div`
  position: relative;
  background-color: #171521;
  padding: 15px 5px;
  @media (min-width: 576px) {
    padding: 20px 15px;
    border-radius: 20px;
  }
  .product-toolbar{
    .btn {
      text-transform: uppercase;
      @media (max-width: 575.98px) {
        text-transform:inherit;
        border-radius: 6px !important;
        .text {
          padding: 4px 8px !important;
          font-size: 14px !important;
          line-height: 24px !important;
        }
      }
    }
  }
`;

const CtaExpert = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  text-align: center;
  .btn-text {
    display: flex;
    align-items: center;
    color: #535353;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      > svg {
        fill: #535353;
      }
      @media (min-width: 992px) {
        height: 28px;
      }
      + .text {
        margin-left: 5px;
      }
    }
    .text {
      display: inline-flex;
      font-size: 16px;
      line-height: 26px;
      @media (min-width: 992px) {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  a {
    display: block;
    color: #c20000;
    margin-bottom: 0;
    &:hover,
    &:focus {
      color: #161420;
    }
  }
`;

const CtaBodyExport = styled.div``;

const SimilarGrid = styled.div``;

const SimilarGridItem = styled.div`
  position: relative;
  width: 100%;
  .product-body {
    @media (min-width: 1600px) {
      margin: 0 20px;
    }
  }
  .product-toolbar {
    .product-item-tool {
      .btn .text {
        @media (min-width: 1600px) {
          padding: 8px 15px !important;
        }
      }
    }
  }
`;
const SlickCarousel = styled.div`
  position: relative;
`;
const SlickSlideCarousel = styled.div`
  .slick-slide {
    position: relative;
    .card {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 0.5s ease;
        opacity: 1;
      }
    }
    &.slick-current {
      .card {
        &:after {
          opacity: 0;
        }
      }
    }
  }

  .slick-center {
    position: relative;
    z-index: 1;
    transition: all 0.5s ease;
    .card {
      transform: scale(1.2);
      box-shadow: 0px 20px 30px 0px rgba(57, 57, 57, 50%);
    }
  }
`;

const NextArrow = styled.div`
  position: absolute;
  z-index: 12;
  top: calc(50% - 45px);
  left: -12px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${BreakpointUp.lg`
    left: -18px;
  `}
  svg {
    fill: #ffffff;
    width: 20px;
  }
  &:hover {
    background-color: #0b619b;
    svg {
      fill: #fff;
    }
  }
`;
const PreviousArrow = styled.div`
  z-index: 12;
  position: absolute;
  top: calc(50% - 45px);
  right: -12px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${BreakpointUp.lg`
    right: -18px;
  `}
  svg {
    fill: #ffffff;
    width: 20px;
  }
  &:hover {
    background-color: #0b619b;
    svg {
      fill: #fff;
    }
  }
`;

const SlickItem = styled.div`
  outline: none;
  padding: 0 0.5rem;
`;

const ProductTemplate = ({ data }) => {
  const productData = data.contentfulProduct;
  const allProducts = data.allContentfulProduct.edges;
  const { addToCart } = useContext(Context);
  const sliderRef = useRef(null);

  const settings = {
    centerMode: true,
    centerPadding: "0",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "120px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "80px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0",
          slidesToShow: 1,
        },
      },
    ],
  };

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <Fragment>
      <Section
        ept="160px"
        epb="30px"
        xpt="140px"
        xpb="20px"
        pt="90px"
        pb="15px"
        bgColor="#fff"
        className="section-product"
      >
        <Container>
          <BreadCrumb className="black">
            <Link to="/">Home</Link> <span>{productData.name}</span>
          </BreadCrumb>
          <SectionProduct>
            <SectionLeft>
              <ProductCard className="product">
                <ProductFigure className="product-figure">
                  <GatsbyImage
                    image={productData.image.gatsbyImageData}
                    alt={productData.name}
                  />
                </ProductFigure>
                <ColorPalettes />
              </ProductCard>
            </SectionLeft>

            <SectionRight>
              <PriceBox>
                <PriceBoxHeader>
                  <ProductSku className="product-sku">
                    SKU: PANM#{productData.skuNumber}
                  </ProductSku>
                  <ProductTitle className="product-title h4">
                    {productData.name}
                  </ProductTitle>
                  <ProductPrice className="product-price">
                    <span>Starting Price :</span>
                    {!!productData.price && (
                      <strong>
                        $
                        {productData.price.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </strong>
                    )}
                  </ProductPrice>
                  <HelpText className="product-helptext">
                    (Prices vary by location, & customization)
                  </HelpText>
                </PriceBoxHeader>
                <PriceInnerBox>
                  <RtoInfo className="rto-info">
                    <RtoItemInfo className="rto-item">
                      <span className="icon">
                        <CheckShapeIcon />
                      </span>
                      <span className="text">Financing Options Availabe</span>
                    </RtoItemInfo>
                    <RtoItemInfo className="rto-item">
                      <span className="icon">
                        <CheckShapeIcon />
                      </span>
                      <span className="text">
                        Delivery & Installation Included
                      </span>
                    </RtoItemInfo>
                  </RtoInfo>
                  <ProductToolbar className="product-toolbar">
                    <ProductItemTool className="product-item-tool">
                      <a
                        href="https://panhandlemetals.sensei3d.com/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Design in 3D"
                      >
                        <WhiteOutlineButton text="Customize Now" size="md" />
                      </a>
                    </ProductItemTool>
                    <ProductItemTool className="product-item-tool">
                      <button onClick={() => addToCart(productData)}>
                        <WhiteOutlineButton text="Add To Cart" size="md" />
                      </button>
                    </ProductItemTool>
                  </ProductToolbar>
                </PriceInnerBox>
              </PriceBox>
              <CtaExpert>
                <CtaBodyExport>
                  <span className="btn-text">
                    <span className="icon">
                      <PhoneIcon />
                    </span>
                    <span className="text">
                      Call Our Building Experts Today
                    </span>
                  </span>
                  <a className="h2" href="tel:8067314815">
                    (806) 731-4815
                  </a>
                </CtaBodyExport>
              </CtaExpert>
            </SectionRight>
          </SectionProduct>
        </Container>
      </Section>

      <Section pt="0" pb="0" bgColor="#fff" className="section-product">
        <Container>
          <SectionProduct>
            <SectionLeft>
              <SidebarPrimary>
                <SidebarPrimaryHeader>
                  <SidebarPrimaryTitle>
                    Building Specifications
                  </SidebarPrimaryTitle>
                </SidebarPrimaryHeader>
                <SidebarPrimaryBody>
                  {!!productData.specifications && (
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html:
                          productData.specifications.childMarkdownRemark.html,
                      }}
                    />
                  )}
                </SidebarPrimaryBody>
              </SidebarPrimary>
            </SectionLeft>
            <SectionRight>
              <Sidebar className="isSticky">
                <SidebarDefault>
                  <SidebarDefaultBody>
                    <ProductForm />
                  </SidebarDefaultBody>
                </SidebarDefault>
              </Sidebar>
            </SectionRight>
          </SectionProduct>
        </Container>
      </Section>

      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="20px"
        pb="20px"
        bgColor="#fff"
        className="section-selling"
      >
        <Container>
          <SectionTitle textAlign="center" mb="20px">
            <span>Popular</span> Products
          </SectionTitle>
          <SlickCarousel>
            <SlickSlideCarousel>
              <Slider {...settings} ref={sliderRef}>
                {allProducts.map((item, i) => (
                  <SlickItem key={i}>
                    <SimilarGridItem className="item" key={i}>
                      <ProductCards
                        sku={item.node.skuNumber}
                        data={item.node}
                      />
                    </SimilarGridItem>
                  </SlickItem>
                ))}
              </Slider>
            </SlickSlideCarousel>
            <NextArrow onClick={() => next()}>
              <ArrowRightIcon />
            </NextArrow>
            <PreviousArrow onClick={() => previous()}>
              <ArrowLeftIcon />
            </PreviousArrow>
          </SlickCarousel>
        </Container>
      </Section>
    </Fragment>
  );
};

const ProductTemplatePage = ({ data, location }) => {
  const productData = data.contentfulProduct;

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Seo title={productData.name} description="description" />

      <ProductTemplate data={data} location={location} />
    </Layout>
  );
};

export default ProductTemplatePage;

export const pageQuery = graphql`
  query ContentPageTemplateQuery($id: String!, $categoryName: String!) {
    contentfulProduct(id: { eq: $id }) {
      id
      name
      url
      skuNumber
      price
      specifications {
        childMarkdownRemark {
          html
        }
      }
      category {
        name
      }
      image {
        gatsbyImageData(placeholder: BLURRED, quality: 90)
      }
    }
    allContentfulProduct(
      sort: { order: ASC, fields: skuNumber }
      filter: { category: { name: { eq: $categoryName } }, id: { ne: $id } }
      limit: 12
    ) {
      edges {
        node {
          id
          name
          skuNumber
          price
          url
          category {
            name
          }
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 90)
          }
        }
      }
    }
  }
`;
